
<!-- 
   auto generated by order_menu.py. DO NOT EDIT.
   source: menu_type_2.py

   sample command:

   python3 order_menu.py [3,5,1,2] 2 > menu_type_2.html
-->

    <div class="main-menu-1 menu-col" *ngIf="show_event" >
      <a [routerLink]="['/events']" routerLinkActive="router-link-active">
        <div class="menu-col-in">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getEventphoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getEvent() ||  ('events.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <div class="main-menu-7 menu-col" *ngIf="show_news">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/news']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getNewsphoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getNews() ||  ('news.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <div class="main-menu-8 menu-col" *ngIf="show_news2">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/news2']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getNews2photo() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getNews2() ||  ('news.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <div class="main-menu-13 menu-col" *ngIf="show_member">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/members']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getMemberphoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getMember() || ('members.title' | translate) }}
      </span>
      </div>
    </a>
    </div>
    <div class="main-menu-14 menu-col" *ngIf="show_forum">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/forum']"
        routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getForumphoto() +')'}"></div>
        <span [class]="'forum-menu' + (forumBadge ? '' : ' x')">
          {{ menuAlias?.getForum() ||  ('forum.title' | translate) }}
          <mat-icon [class]="'badge'" matBadgeColor="warn" matBadge="{{ forumBadge }}" *ngIf="forumBadge">chat</mat-icon>
        </span>
      </div>
      </a>
    </div>
    <div class="main-menu-23 menu-col" *ngIf="show_links">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/links']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getLinkphoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getLink() ||  ('links.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <div class="main-menu-17 menu-col" *ngIf="show_album">
      <a [routerLink]="['/albums']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getAlbumphoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getAlbum() ||  ('albums.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
   <div class="main-menu-22 menu-col" *ngIf="show_cpsurl">
      <a [href]="cpsurl" target="_blank" routerLinkActive="router-link-active">
        <div class="menu-col-in">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getCpslinkphoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getCpslink() ||  ('cpsurl.title' | translate) }}
        </span>
        </div>
      </a>
    </div>
    <div class="main-menu-24 menu-col" *ngIf="show_links2">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/links2']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getLinkphoto2() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getLink2() ||  ('links.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <div class="main-menu-25 menu-col" *ngIf="show_links3">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/links3']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getLinkphoto3() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getLink3() ||  ('links.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <div class="main-menu-26 menu-col" *ngIf="show_links4">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/links4']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getLinkphoto4() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getLink4() ||  ('links.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <div class="main-menu-27 menu-col" *ngFor="let menu of menus$ | async menus">
      <a target="_blank" [href]="menu.getUrl()" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menu.getPhoto()  +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menu.getName() }}
        </span>
      </div>
      </a>
     </div>
    <div class="main-menu-0 menu-col" *ngIf="show_place">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/places']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getPlacephoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getPlace() ||  ('places.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <div class="main-menu-2 menu-col" *ngIf="show_shop">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/shop']"
        routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getShopphoto() +')'}"></div>
        <span [class]="'shop-menu' + (cartBadge ? '' : ' x')">
          {{ menuAlias?.getShop() ||  ('shop.title' | translate) }}
          <mat-icon [class]="'badge'" matBadgeColor="warn" matBadge="{{ cartBadge }}" *ngIf="cartBadge">shopping_cart</mat-icon>
        </span>
      </div>
      </a>
    </div>
    <div class="main-menu-3 menu-col" *ngIf="show_stream">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/streams']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getStreamphoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getStream() ||  ('streams.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <div class="main-menu-4 menu-col" *ngIf="show_stream2">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/streams2']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getStreamphoto2() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getStream2() ||  ('streams.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <div class="main-menu-5 menu-col" *ngIf="show_stream3">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/streams3']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getStreamphoto3() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getStream3() ||  ('streams.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <div class="main-menu-6 menu-col" *ngIf="show_stream4">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/streams4']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getStreamphoto4() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getStream4() ||  ('streams.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <div class="main-menu-9 menu-col" *ngIf="show_news3">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/news3']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getNews3photo() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getNews3() ||  ('news.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <div class="main-menu-10 menu-col" *ngIf="show_news4">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/news4']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getNews4photo() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getNews4() ||  ('news.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <div class="main-menu-11 menu-col" *ngIf="show_competition">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/competitions']" routerLinkActive="router-link-active">
        <div class="menu-col-in">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getCompetitionphoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getCompetition() ||  ('competitions.title' | translate) }}
        </span>
        </div>
      </a>
    </div>
    <div class="main-menu-12 menu-col" *ngIf="show_leader">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/leaderboard']" routerLinkActive="router-link-active">
        <div class="menu-col-in">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getLeaderboardphoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getLeaderboard() ||  ('leaderboard.title' | translate) }}
        </span>
        </div>
      </a>
    </div>
    <div class="main-menu-15 menu-col" *ngIf="show_restaurant">
      <a [ngStyle]="{'color': textColor}" (click)="open_restaurant()"
        routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getRestaurantphoto() +')'}"></div>
        <span [class]="'restaurant-menu' + (mealBadge ? '' : ' x')">
          {{ menuAlias?.getRestaurant() ||  ('menus.title' | translate) }}
          <mat-icon [class]="'badge'" matBadgeColor="warn" matBadge="{{ mealBadge }}" *ngIf="mealBadge">restaurant</mat-icon>
        </span>
      </div>
      </a>
    </div>
    <div class="main-menu-16 menu-col" *ngIf="show_benefit">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/benefits']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getBenefitphoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getBenefit() ||  ('benefits.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <div class="main-menu-18 menu-col" *ngIf="show_shopprofile">
      <a [routerLink]="['/about']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getAboutusphoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getAboutus() ||  ('about.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <div class="main-menu-20 menu-col" *ngIf="show_document">
      <a [routerLink]="['/documents']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getDocumentphoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getDocument() ||  ('document.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <div class="main-menu-32 menu-col" *ngIf="show_news7">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/news7']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getNews7photo() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getNews7() ||  ('news.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <div class="main-menu-33 menu-col" *ngIf="show_news8">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/news8']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getNews8photo() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getNews8() ||  ('news.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <div class="main-menu-34 menu-col" *ngIf="show_news9">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/news9']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getNews9photo() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getNews9() ||  ('news.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <div class="main-menu-28 menu-col" *ngIf="show_golf_teaching_class">
      <a [routerLink]="['/golf_class']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getGolfclasslinkphoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getGolfclasslink() ||  ('golf_class.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <div class="main-menu-29 menu-col" *ngIf="show_room_reservation">
      <a [routerLink]="['/guest/reservations']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getRoomreservationphoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getRoomreservation() ||  ('guest.menu-reservation' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <div class="main-menu-30 menu-col" *ngIf="show_news5">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/news5']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getNews5photo() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getNews5() ||  ('news.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <div class="main-menu-31 menu-col" *ngIf="show_news6">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/news6']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getNews6photo() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getNews6() ||  ('news.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
